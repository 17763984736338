import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path d="m42,3l-39,22l0,45l39,23l39,-22l0,-45l-39,-23z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="5" stroke="currentColor"/>
        <path fill="currentColor" d="m44.18617,63.35l-11.75,0l0,-30.7l10.75,0c6.4,0 8.37619,10.35 8.37619,15.85c0.06124,5.48559 -1.7723,14.8406 -7.37619,14.85zm-6.7,-3.87143l5.53095,0c3.55,0 3.78333,-7.89762 3.78333,-10.79762s-0.11429,-11.53095 -3.66429,-11.53095l-5.65,0l0,22.32857z"/>
      </g>
    </g>
  </svg>
);

export default IconLogo;
