import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="D" transform="translate(9.000000, 4.000000)">
        <path
          d="m44.18617,63.35l-11.75,0l0,-30.7l10.75,0c6.4,0 8.37619,10.35 8.37619,15.85c0.06124,5.48559 -1.7723,14.8406 -7.37619,14.85zm-6.7,-3.87143l5.53095,0c3.55,0 3.78333,-7.89762 3.78333,-10.79762s-0.11429,-11.53095 -3.66429,-11.53095l-5.65,0l0,22.32857z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
